<template>
  <div class="contents">
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.title"
        prop="title"
        name="title"
        label="Title"
        type="textarea"
      />
    </div>
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.subtitle"
        prop="subtitle"
        name="subtitle"
        label="Subtitle"
        type="textarea"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
